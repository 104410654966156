import React from "react"
import ListItem from "./listItem"

const howto = [
  {
    name: "5-Minute Crafts",
    subs: "57.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_Ig-3kRzbVXf3NRh3XWDFVyzXRANCQsltkvQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC295-Dw_tDNtZXFeAPAW6Aw",
    rank: 1,
  },
  {
    name: "BRIGHT SIDE",
    subs: "27.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-ZcFPE67abV8IDXicI5S8I7k5doOlcHrXQPg=s88-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC4rlAVgAK0SGk-yTfe48Qpw",
    rank: 2,
  },
  {
    name: "Yuya",
    subs: "23.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-DXRX5u_v4lPod3zYkmHMtAYIMB5-EXLVFLA=s288-mo-c-c0xffffffff-rj-k-no ",
    link: "https://www.youtube.com/channel/UCBNs31xysxpAGMheg8OrngA",
    rank: 3,
  },
  {
    name: "GENIAL",
    subs: "17.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_DaP1visPLg-P2_zQaENu3-wX-qbgj6YVWLw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCbrd1vu4_7qIE6IPV_dA-OA",
    rank: 4,
  },
  {
    name: "jeffreestar",
    subs: "15.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-LIHkWZNgM3aYk0GbQ37NvcUk7a4A2Hb-B2A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCkvK_5omS-42Ovgah8KRKtg",
    rank: 5,
  },
  {
    name: "5-Minute Crafts GIRLY",
    subs: "15M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-W7LyNgUJjBcsabw4Qh0eEGbW8hVOf-Jkg7g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC2etEuPIfohP4P53wM0KImA",
    rank: 6,
  },
  {
    name: "Tasty",
    subs: "14.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79wniZfBdNgKPqOAmbEOGvIcEZmxBCOYfsDcw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCJFp8uSYCjXOMnkUyb3CQ3Q",
    rank: 7,
  },
  {
    name: "5-Minute Crafts KIDS ",
    subs: "13.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78fFvdKMiRPOFevbwtJuIReVVaOLnEJ5fnD3g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC57XAjJ04TY8gNxOWf-Sy0Q",
    rank: 8,
  },
  {
    name: "Troom Troom",
    subs: "13.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78Pi8xVIP6pCP_udl8AhlChOZYoaqJ7UjlEKg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCWwqHwqLSrdWMgp5DZG5Dzg",
    rank: 9,
  },
  {
    name: "Wengie",
    subs: "13.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79nbsB_6XFxz7ATtlql-XbdB0GpAl_tvV1OPw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCD9PZYV5heAevh9vrsYmt1g",
    rank: 10,
  },
  {
    name: "HowToBasic",
    subs: "13.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-7prx8ym1JsklALSNKwgRju4eDjqi8T3gcJg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCR4s1DE9J4DHzZYXMltSMAg",
    rank: 11,
  },
  {
    name: "Musas",
    subs: "12.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_OynaiBinlpqJ5vYSPvHEK5KFy37_04kKjwA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UClnKzwo9CPkYix6V9jK4JTQ",
    rank: 12,
  },
  {
    name: "NikkieTutorials",
    subs: "12M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-_CjY7fvmL4PUvXYDatA_8bRLCPr8NKMiX8A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCzTKskwIc_-a0cGvCXA848Q",
    rank: 13,
  },
  {
    name: "Zoella",
    subs: "11.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78FrKVmn8UoHt3HkHrtJojBiVeFpXKnRqyjuw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCWRV5AVOlKJR1Flvgt310Cw",
    rank: 14,
  },
  {
    name: "Rosanna Pansino",
    subs: "11.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-0fGvPssLw7V4DB8BofSP4vHzRxMmVoxeBwA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCjwmbv6NE4mOh8Z8VhPUx1Q",
    rank: 15,
  },
  {
    name: "Blossom",
    subs: "10.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79LquBq-jAifeL7aQi60AJrUBtzS336bJdzog=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC2WuPTt0k8yDJpfenggOAVQ",
    rank: 16,
  },
  {
    name: "ExpCaseros",
    subs: "10.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79zKxD3sTgnz2j0eEaUIYyAnCkuwE60rgczdg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCoTyVfIkM-hjz4JauDoVvog",
    rank: 17,
  },
  {
    name: "Bethany Mota",
    subs: "10.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78uFDKuERIEQlDGVaQ2ZYJZ6wAPKXCtiABC5w=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCc6W7efUSkd9YYoxOnctlFg",
    rank: 18,
  },
  {
    name: "IDEAS EN 5 MINUTOS",
    subs: "10.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_DpeOF-BjAIRKk_A4sr3wJx-i7ZEd_p9BtEw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC_OLtzRJdg0MJfiqGWAAIHw",
    rank: 19,
  },
  {
    name: "SaraBeautyCorner - DIY, Comedy, Makeup, Nail Art",
    subs: "10.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79_EsHSe8aqgCMm3ybary1ATgo32Ltgvi7klQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC0YvTCy1I4_a-3pn47_5DBA",
    rank: 20,
  },
  {
    name: "MyLifeAsEva",
    subs: "10.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78FDctzUb0wBh_TZz1GxloIoZrql4LJh823=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCAoMPWcQKA_9Af5YhWdrZgw",
    rank: 21,
  },
  {
    name: "Tati",
    subs: "9.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_Gb7q4CiMk_bETLsAOC6G5JM4K6eZbGRkaKQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC4qk9TtGhBKCkoWz5qGJcGg",
    rank: 22,
  },
  {
    name: "Troom Troom Es",
    subs: "9.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_FSpAw8qFJrxavLth9WNXnSNzWGpSetb5pQA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCu6oyJJ6PlkeNNv6n26ZNyA",
    rank: 23,
  },
  {
    name: "HellomaphieMX",
    subs: "9.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_cPzot2u8eSa2Nds0ch0P_o8gTJjL6Yn6F6g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCap97Ue8K_BpKlrvQRYd6JA",
    rank: 24,
  },
  {
    name: "LaurDIY",
    subs: "9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-l4Aou6X21RwEOnbwfQnH10RXzaVghUQGEwQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCap97Ue8K_BpKlrvQRYd6JA",
    rank: 25,
  },
  {
    name: "Karina Garcia",
    subs: "9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79P6K1hQXqV3pmIIvbXek2f-AWYP5MqO8M2pg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCTTJMptGhfJA67e40DlqbNw",
    rank: 26,
  },
]

const HowTo = () => <ListItem data={howto} />

export default HowTo

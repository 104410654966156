import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HowTo from "../components/howto"
import SelectRouter from "./selectRouter"

const HowToPage = () => {
  return (
    <Layout>
      <SEO
        title="Top YouTube Gaming Channels"
        description="youtuber.life brings you top youtube gaming channels"
      />
      <SelectRouter />
      <HowTo />
    </Layout>
  )
}
export default HowToPage
